// need this import to load polyfill;
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';

import { Unit } from '@formatjs/intl-relativetimeformat';
import { IntlShape } from 'dibs-react-intl';

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;

const thresholds = {
    second: 45, // seconds to minute
    minute: 45, // minutes to hour
    hour: 22, // hour to day
    day: 5, // day to week
};

function selectUnit(dateOrString: Date | string): { value: number; unit: Unit } {
    const date = typeof dateOrString === 'string' ? new Date(dateOrString) : dateOrString;
    const delta = date.getTime() - new Date().getTime();

    const secs = delta / second;
    if (Math.abs(secs) < thresholds.second) {
        return {
            value: Math.round(secs),
            unit: 'second',
        };
    }

    const mins = delta / minute;
    if (Math.abs(mins) < thresholds.minute) {
        return {
            value: Math.round(mins),
            unit: 'minute',
        };
    }

    const hours = delta / hour;
    if (Math.abs(hours) < thresholds.hour) {
        return {
            value: Math.round(hours),
            unit: 'hour',
        };
    }

    const days = delta / day;
    return {
        value: Math.round(days),
        unit: 'day',
    };
}

export const formatRelativeDate = function formatRelativeDate(
    intl: IntlShape,
    dateOrString: Date | string
): string {
    const { value, unit } = selectUnit(dateOrString);
    return new Intl.RelativeTimeFormat(intl.locale).format(value, unit);
};
