import { FunctionComponent } from 'react';

// components
import { PriceAmount } from './PriceAmount';

type Props = {
    price?: number;
    originalPrice?: number;
    percentageOff?: number;
    currency: string;
    quantityDisplay: string | null;
    hasPrivateOffer?: boolean;
    displayVatLabel?: boolean;
    priceBookName: string | null;
    mwStickyHeaderCtaVariant?: boolean;
};

/**
 * Price Components for an item with a net price
 *  (Net or Net & Retail)
 */
export const NetItemPrices: FunctionComponent<Props> = ({
    price,
    originalPrice,
    currency,
    percentageOff,
    quantityDisplay,
    hasPrivateOffer,
    displayVatLabel,
    priceBookName,
    mwStickyHeaderCtaVariant = false,
}) => (
    <>
        {/* NET PRICE */}
        {!!price && (
            <PriceAmount
                type="NET"
                showLabel={!mwStickyHeaderCtaVariant}
                amount={price}
                currency={currency}
                quantityDisplay={quantityDisplay}
                hasPrivateOffer={hasPrivateOffer}
                displayVatLabel={displayVatLabel}
                priceBookName={priceBookName}
            />
        )}
        {/* RETAIL PRICE */}
        {!!originalPrice && !hasPrivateOffer && !mwStickyHeaderCtaVariant && (
            <PriceAmount
                hasNetPrice
                discount={percentageOff}
                currency={currency}
                amount={originalPrice}
                quantityDisplay={quantityDisplay}
                hasPrivateOffer={hasPrivateOffer}
                priceBookName={priceBookName}
            />
        )}
    </>
);
