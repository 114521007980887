import { useState, useEffect } from 'react';
import { ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';
import { getAbTestV2, trackAbTestV2Variant } from 'dibs-ab-tests/exports/clientAbTestV2';

// constants
import { TEXT_ONLY_PRICE_TYPES } from './constants';

// types
import { PriceDisplay_item$data as ItemType } from './__generated__/PriceDisplay_item.graphql';

type DisplayPriceType = ArrayElement<ItemType['displayPrice']>;
type ConvertedAmountListType = NonNullable<DisplayPriceType>['convertedAmountList'];

export const getPriceFromAmountListByCurrency = (
    convertedAmountList: ConvertedAmountListType,
    currency: string
): number | undefined => {
    const priceAmount = (convertedAmountList || []).find(
        convertedAmount => convertedAmount && convertedAmount.currency === currency
    );

    return (priceAmount && priceAmount.amount) || undefined;
};

export const isValidTextOnlyPriceType = (type: string | null): boolean => {
    return type ? TEXT_ONLY_PRICE_TYPES.includes(type) : false;
};

export const PDP_PRICE_ROUNDING_TEST_NAME = 'pdpPriceRounding';

export const usePdpPriceRoundingClientVariant = (): boolean | null => {
    const [isPdpPriceRoundingClient, setIsPdpPriceRoundingClient] = useState<boolean | null>(null);

    useEffect(() => {
        setIsPdpPriceRoundingClient(
            getAbTestV2(PDP_PRICE_ROUNDING_TEST_NAME)?.variant === 'variant'
        );
    }, []);

    return isPdpPriceRoundingClient;
};

export const checkIsPdpPriceRoundingClientVariant = (): boolean => {
    return getAbTestV2(PDP_PRICE_ROUNDING_TEST_NAME)?.variant === 'variant';
};

const isPaidTraffic = (): boolean => {
    const utm_content = new URLSearchParams(window.location.search).get('utm_content') || '';
    return utm_content === 'paid';
};

export const trackPdpPriceRoundingAbTestVariant = (): void => {
    if (!isPaidTraffic()) {
        trackAbTestV2Variant(PDP_PRICE_ROUNDING_TEST_NAME);
    }
};

export const getRoundedPrice = (amount: number): number => {
    return !isPaidTraffic() && amount > 1 ? Math.floor(amount) : amount;
};
