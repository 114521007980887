import { FunctionComponent } from 'react';

// components
import { PriceAmount, PriceAmountProps } from './PriceAmount';

interface Props extends PriceAmountProps {
    price?: number;
    originalPrice?: number;
    percentageOff?: number;
    mwStickyHeaderCtaVariant?: boolean;
}

/**
 * Price Components for an item on sale
 *  (Sale or Sale & Original)
 */
export const SaleItemPrices: FunctionComponent<Props> = ({
    price,
    originalPrice,
    currency,
    percentageOff,
    quantityDisplay,
    hasPrivateOffer,
    appendElementToPrice,
    displayVatLabel,
    priceBookName,
    mwStickyHeaderCtaVariant = false,
}) => (
    <>
        {/* SALE PRICE */}
        <PriceAmount
            type="SALE"
            currency={currency}
            amount={price}
            quantityDisplay={quantityDisplay}
            appendElementToPrice={appendElementToPrice}
            displayVatLabel={displayVatLabel}
            priceBookName={priceBookName}
        />
        {/* ORIGINAL PRICE */}
        {!!originalPrice && !mwStickyHeaderCtaVariant && (
            <PriceAmount
                hasSalePrice
                discount={percentageOff}
                currency={currency}
                amount={originalPrice}
                quantityDisplay={quantityDisplay}
                hasPrivateOffer={hasPrivateOffer}
                priceBookName={priceBookName}
            />
        )}
    </>
);
